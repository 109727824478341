<template>
  <div class="SwitchSelector">
    <p v-html="option1" :class="{ 'active': value }"></p>
    <div class="SwitchSelector--input" v-show="!disabled" @click="toggle" :class="{ 'active': value }">
      <div></div>
    </div>
    <p v-html="option2" :class="{ 'active': !value }"></p>
    <p class="SwitchSelector--Optional" v-if="tag.length > 0">{{ tag }}</p>
  </div>
</template>

<script>
export default {
  name: 'SwitchSelector',
  props: {
    option1: {
      type: String,
      required: true
    },
    option2: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: ""
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value() {
      return this.modelValue;
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.$emit('update:modelValue', !this.value);
    }
  }
};
</script>
<style scoped lang="scss">
.SwitchSelector{
  display: flex;
  align-items: center;
  gap: 10px;
  &--Optional{
    padding: 5px 6px;
    background: #EAEAEA;
    border-radius: 4px;
    font-family: 'Lato';
    font-weight: 900!important;
    font-size: 12px!important;
    text-transform: uppercase;
  }
  &--input{
    background: #9B9B9B;
    width: 36px;
    height: 14px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active{
      background: #A472EA;
      div{
        margin-left: 0;
        opacity:1;
        background: #8134FF;
      }
    }
    div{
      background: white;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      transition: 0.5s;
      box-shadow: 1px 1px 1px rgba(51, 51, 51, 0.25);
      margin-left: calc(100% - 20px);
    }
  }
  p{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    transition: 0.2s;
    &.active{
      font-weight: 900;
      color: #8134FF;
    }
  }
}
</style>
