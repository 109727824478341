
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import StudyUXTasks from '@/components/StudyCreation/StudyUXTasks.vue'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import TestPreview from '@/components/TestPreview.vue'
import store from '@/store'
import { ProductTypes } from '@/utils/constants'
import { addTask, removeTask, duplicateTask, generateNavigationRules, navigationRulesHasErrors, formatNavigationRules } from '~/utils/survey-creation.ts'
import TaskRandomModal from '@/components/StudyCreation/TaskRandomModal.vue'
import { useRoute, useRouter } from 'vue-router';


export default defineComponent({
  name : 'Step3Survey',
  components:{
    StudyCreationBase,
    StudyUXTasks,
    ProgressModal,
    TestPreview,
    TaskRandomModal
  },
  setup() {
  const router = useRouter()
  const route = useRoute()

  return {
    router,
    route
  }
},
  props:{
    STEP:[String, Number],
    type:String
  },
  data(){
    return{
      testName: '',
      tasks: [{}],
      navigationRules:[],
      errorMessage:'',
      error: false,
      dataIsUpdated: false,
      testLoaded: false,
      showProgress: false,
      showGeneralRules: false,
      showTestPreview: false,
      executionPreviewTestId:'',
      showTaskRandomModal: false,
      onlySave: false,
      activeTaskIndex: 0 
    }
  },
  computed: {
    ...mapGetters([
      'surveySteps'
    ]),
    watUxStep2Route(){
      if(this.type == ProductTypes.WAT_NEURO) return 'newstudy.step2WatNeuroEdit'
      else if(this.type == ProductTypes.CLICK_TEST) return 'newstudy.step2ClickTestEdit'
      else return 'newstudy.step2WatUxEdit'
      
    }
  },
  methods:{
    async fetchTest(){
      await store.dispatch('getTest',{testId:this.route.params.testId}).then((data) => {
        for(let t of data.testTasks){
          if (t.uxData && !t.uxData.onlyRecord && (!t.uxData.checkpoints || t.uxData.checkpoints.length === 0)){
            t.uxData.onlyRecord = true
            t.uxData.checkpoints = []
          }
          if(t.uxQuantiTask && t.uxQuantiData == undefined){
            t.uxQuantiData = {
              desktopStartUrl: '',
              desktopSuccessUrls: [{name:'',url:''}],
              mobileStartUrl: '',
              mobileSuccessUrls: [{name:'', url:''}],
              maxTime: 1,
              minTime: 1
            }
          }
          if(t.uxTask && t.uxData == undefined){
            t.uxData = {
              initialUrl: '',
              recordScreen: false,
              recordMicrophone: false,
              recordWebcam: false,
              checkpoints: [],
              onlyRecord: false
            }
          }
          else if(t.uxTask && t.uxData != undefined){
            t.uxData.onlyRecord = t.uxData.checkpoints?.length == 0
          }
          if(t.clickTestTask && t.clickTestData == undefined){
            t.clickTestData = {
              imageUrl:'',
              timeoutSeconds:0,
              message:'',
              successZoneTL_X:0,
              successZoneTL_Y:0,
              successZoneBR_X:0,
              successZoneBR_Y:0
            }
          }
          if (t.taskType == "ONLY_QUESTIONS") {
            t.uxQuantiTask = false;
            t.uxTask = false;
            t.clickTestTask = false;
          }
          // Asegurar que questions esté definido
          if(t.questions == undefined) t.questions = []
        }
        this.executionPreviewTestId = data.test.publicToken
        this.testName = data.test.title
        this.tasks = data.testTasks
        this.testLoaded = true
      })
    },
    addTask(){
      const taskType = this.route.path.split('/')[2]; 
      addTask(this.tasks,taskType)
      this.activeTaskIndex = this.tasks.length - 1; 
      this.dataIsUpdated = true
    },
    duplicateTask(task: any){
      const {identifier, ...taskWithoutId} = task
      taskWithoutId.name += " - Copy"
      duplicateTask(this.tasks, taskWithoutId)
      this.activeTaskIndex = this.tasks.length - 1;
      this.dataIsUpdated = true
    },
    removeTask(index: any){
      removeTask(this.tasks, index)
      this.dataIsUpdated = true
      if (this.activeTaskIndex >= this.tasks.length) {
        this.activeTaskIndex = Math.max(0, this.tasks.length - 1); 
      }
    },
    updateTasks(updatedTasks:any) {
      this.tasks = updatedTasks;
      this.dataIsUpdated = true;
    },

    updateTabIndex(index: number) {
      this.activeTaskIndex = index; 
    },
    beforeSave(onlySave: Boolean){
      this.errorMessage = ""
      this.error = false
      const StudyUXTasks: any = this.$refs.StudyUxTasks
      const taskErrors = StudyUXTasks.hasErrors()
      const navigationRulesError = navigationRulesHasErrors(this.navigationRules, true)
      if(navigationRulesError.error) {
        this.error = true
        console.log(navigationRulesError)
        for(let ed of navigationRulesError.errorDetail){
          this.errorMessage += this.$t('suite_watux_step3_error_rules',{question:ed.qId, task:ed.taskNum}) + '<br>'
        }
        return
      }
      if(taskErrors.length > 0){
        this.error = true
        for(let te of taskErrors){
          this.errorMessage += this.$t('suite_watux_step3_error_tasks', {task:te.taskNum}) + '<br>'
        }
        return
      }
      if(this.type == ProductTypes.WAT_UX && this.tasks.length > 1){
        this.openTaskRandomModal(onlySave)
      }
      else this.saveTasks(onlySave)
    },
    async saveTasks(onlySave: Boolean){
      const test = {
        testName: this.testName,
        tasks: this.tasks,
        navigationRules: {
          conditions:formatNavigationRules(this.navigationRules, false)
        }
      }
      this.showProgress = true
      await store.dispatch(this.getSaveTaskServiceName(), {contractId:this.route.params.id, data:test})
      .then((data) => {
        this.showProgress = false
        if(onlySave) {
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          this.dataIsUpdated = false
          return
        }
        else this.router.push({name: this.getRouterByStudyType(), params:{id: this.route.params.id, testId:this.route.params.testId}})
      })
      .catch((response)  => {
        this.error = true
        this.errorMessage = this.$t('suite_study_error_save')
        this.showProgress = false
      })
    },
    openTaskRandomModal(onlySave: any){
      this.showTaskRandomModal = true
      this.onlySave = onlySave
    },
    closeTaskRandomModal(event: any){
      this.showTaskRandomModal = false
      this.saveTasks(this.onlySave)
    },
    async fetchNavigationRules(){
      await store.dispatch('fetchTestNavigationRules',{testId:this.route.params.testId}).then((data) => {
        generateNavigationRules(this.navigationRules, data)
      })
    },
    openPreview(){
      if(this.dataIsUpdated){
        Swal.fire({
          text:this.$t('msg_new_activity_open_preview'),
          icon:"warning",
          customClass:{popup:"swal-customWarning"}
        })
        return
      }
      this.showTestPreview = true
    },
    getSaveTaskServiceName(){
      if(this.type == ProductTypes.WAT_UX) return 'saveWATUx'
      else if(this.type == ProductTypes.WAT_UX_QUANTI) return 'saveWATUxQuanti'
      else if(this.type == ProductTypes.CLICK_TEST) return 'saveClickTest'
      else if(this.type == ProductTypes.WAT_NEURO) return 'saveWatNeuro'
      else return ''
    },
    getRouterByStudyType(){
      if(this.type == ProductTypes.WAT_UX) return 'newstudy.step4WatUx'
      else if(this.type == ProductTypes.WAT_UX_QUANTI) return 'newstudy.step4WatUxQuanti'
      else if(this.type == ProductTypes.CLICK_TEST) return 'newstudy.step4ClickTest'
      else if(this.type == ProductTypes.WAT_NEURO) return 'newstudy.step4WatNeuro'
      else return ''
    }
  },
  async created(){
    await this.fetchTest(),
    await this.fetchNavigationRules()
  },
  async mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
  }
})
